<!-- eslint-disable import/no-cycle -->
<template>
  <div>

    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="dark"
          icon="FilterIcon"
          :statistic="statistiques.allPublicOffers"
          statistic-title="Opportunités de fret"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="TrendingUpIcon"
          :statistic="statistiquesDevis.societeDevisSoumis"
          statistic-title="Devis soumis"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="primary"
          icon="CheckIcon"
          :statistic="statistiquesDevis.societeDevisAcceptes"
          statistic-title="Devis acceptés"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="CheckSquareIcon"
          :statistic="statistiquesDevis.societeDevisSuspendus"
          statistic-title="Devis suspendus"
          color="danger"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="TrendingDownIcon"
          :statistic="statistiquesDevis.societeDevisRejetes"
          statistic-title="Devis rejetés"
          color="danger"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          icon="TruckIcon"
          :statistic="statistiquesDevis.societeMoyenTransports"
          statistic-title="Camions"
          color="info"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="UsersIcon"
          :statistic="statistiquesDevis.societeTransporteursInternes"
          statistic-title="Transporteurs Internes"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-vertical
          color="primary"
          icon="FileTextIcon"
          :statistic="statistiquesInvoices.facturesGenerees"
          statistic-title="Factures générées"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { onMounted } from '@vue/composition-api'

import { BRow, BCol } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useOffres from '@/services/affreteur/offresService'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'
// eslint-disable-next-line import/no-cycle
import useInvoices from '@/services/invoice/invoiceService'

import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  setup() {
    const {
      getAllPublicOffers, statistiques,
    } = useOffres()
    const {
      getAllDevisBySocieteAndAccepte, statistiquesDevis, getAllDevisBySocieteAndRejet, getAllDevisBySocieteAndState, getAllDevisBySocieteAndSuspendus, getTransporteursMeans,
      getTransporteurs,
    } = useSocieteTransporteurs()
    const {
      getInvoicesByTransporter, statistiquesInvoices,
    } = useInvoices()
    onMounted(async () => {
      await getAllPublicOffers()
      await getAllDevisBySocieteAndAccepte()
      await getAllDevisBySocieteAndRejet()
      await getAllDevisBySocieteAndState()
      await getAllDevisBySocieteAndSuspendus()
      await getTransporteursMeans()
      await getTransporteurs()
      await getInvoicesByTransporter()
      console.clear()
    })
    return {
      statistiques,
      statistiquesDevis,
      statistiquesInvoices,
    }
  },
}
</script>
